import React from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image";

const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 75vh;
  margin-bottom: 1.45rem;
`;

const AboutMe = styled.div`
  font-size: 22px;
  min-width: 100px;
`;

const BlockQuote = styled.blockquote`
  & {
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    max-height: 320px;
    color: #4a4a4a;
    @media (max-width: 768px) {
      overflow: scroll;
    }
  }
  &:before {
    color: #4a4a4a;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  & p {
    display: inline;
  }
`;

const QuoteAuthor = styled.div`
  text-align: right !important;
  font-weight: bold;
  color: #4a4a4a;
`;

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="About" description="About" />
      <AboutContainer>
        <StaticImage
          className={`custom-img`}
          src="../images/TheMartion.png"
          alt="TheMartion.png"
        />
        <BlockQuote>
          <div>
            At some point, everything is going to go south on you. Everything is
            going to go south and you're going to say, "This is it. "This is how
            I end." Now, you can either accept that or you can get to work.
            That's all it is. You just begin. You do the math. You solve one
            problem then you solve the next one. And then the next. And if you
            solve enough problems, you get to come home.
          </div>
          <QuoteAuthor>—The Martian</QuoteAuthor>
        </BlockQuote>
        <AboutMe>一个半路出家的编程爱好者。喜欢折腾，热爱分享。</AboutMe>
      </AboutContainer>
    </Layout>
  );
};

export default IndexPage;
